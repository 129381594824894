import React from "react"
import styled, { keyframes } from "styled-components"
import Layout from "../components/layout"
import BackgroundTitle from "../components/background-title"
import { colors } from "../style/colors"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const Container = styled.div`
  background-color: ${colors.red};
`
const Grid = styled.div`
  padding: 200px 90px 60px;
  display: flex;
  flex-direction: row;
  opacity: 0;

  animation: ${fadeIn} 0.5s ease 1s forwards;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 250px 30px 30px;
  }
`
const TextContainer = styled.div`
  z-index: 1;
  max-width: 45%;
  margin-left: 50px;

  @media (max-width: 900px) {
    max-width: 50%;
  }

  @media (max-width: 800px) {
    margin: 50px 0 0 0;
    max-width: none;
  }

  p {
    font-size: 24px;
    line-height: 120%;
    font-variation-settings: "wght" 550, "PROP" 1, "XPRN" 0;
  }

  p:first-child {
    font-variation-settings: "wght" 800, "PROP" 1, "XPRN" 0;
    color: black;
  }
`
const ImageContainer = styled.div`
  width: 85%;
  display: block;
  border: 8px solid black;
  position: relative;
  overflow: hidden;
  max-height: 600px;
  height: 600px;

  @media (max-width: 1050px) {
  max-height: 400px;
  height: 400px;
  }

  @media (max-width: 800px) {
  max-height: 350px;
  height: 350px;
  margin: 0 auto;
  }
`
const Image = styled.img`
  width: 85%;
  height: 100%;
  margin: 0;
  object-fit: cover;
  border: 8px solid black;

  @media (max-width: 800px) {
  margin: 0 auto;
  width: 100%;
  }

`
const BottomFade = styled.div`
  height: 35vh;
  width: 100%;
  background: rgb(224, 79, 79);
  background: linear-gradient(
    180deg,
    rgba(255, 131, 69, 1) 0%,
    rgba(255, 131, 69, 0.4) 50%,
    rgba(255, 255, 255, 1) 100%
  );
`
const LeftContainer = styled.div`
  width: 85%;
  @media (max-width: 800px){
  width: 100%;

  }
`;
const ButtonContainer = styled.div`
  width: 85%;
  display: flex;
  justify-content: center;
  margin: 20px 0 0;

  @media (max-width: 800px){
  width: 100%;

  }
`;
const Button = styled.button`
  background: black;
  color: white;
  justify-self: center;
  font-family: "Recursive";
  font-variation-settings: "wght" 500, "PROP" 1, "XPRN" 1;
  text-transform: uppercase;
  border: none;
  font-size: 30px;
  padding: 26px 15px;
  cursor: pointer;
  transition: 0.3s ease;

  :hover {
    font-variation-settings: "wght" 900, "PROP" 1, "XPRN" 1;
  }

  :focus {
    outline: none;
  }

  @media (max-width: 600px) {
    font-size: 20px;
    padding: 20px 15px;
  }
`

class AboutPage extends React.PureComponent {
  state = {}
  render() {
    const data = this.props.data.allWordpressPage.edges[0].node
    return (
      <Container>
        <Layout>
          <SEO title="About Me" />
          <BackgroundTitle opacity visible top left title="About Me" />
          <Grid>
            <LeftContainer>
              <ImageContainer>
                <Img
                  style={{
                    width: "100%",
                    height: "100%",
                    margin: 0,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    objectFit: "cover",
                  }}
                  fadeIn
                  objectFit="cover"
                  fluid={data.featured_media.localFile.childImageSharp.fluid}
                />
                {/* <Image
                src={data.featured_media.source_url}
                alt={data.featured_media.alt_text}
              /> */}
              </ImageContainer>
              <ButtonContainer>
                <Button><a style={{ color: 'inherit', textDecoration: 'none' }} href={data.acf.cv.source_url}>View My CV</a></Button>

              </ButtonContainer>
            </LeftContainer>
            <TextContainer
              dangerouslySetInnerHTML={{ __html: data.content }}
            >
            </TextContainer>
          </Grid>


          <BottomFade />
        </Layout>
      </Container>
    )
  }
}

export default AboutPage

export const aboutQuery = graphql`
  query($homepage: String = "About") {
    allWordpressPage(filter: { title: { eq: $homepage } }) {
      edges {
        node {
          title
          content
          acf {
            cv {
              source_url
            }
            image_1 {
              source_url
              alt_text
            }
            image_2 {
              source_url
              alt_text
            }
            image_3 {
              source_url
              alt_text
            }
          }
          featured_media {
            source_url
            alt_text
            localFile {
                childImageSharp {
                  fluid(maxHeight: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
          }
        }
      }
    }
  }
`
